<template>
<div class="container">
    <div class="row">
        <div class="col">
            <span class="small text-muted float-right my-1"> API: {{ api_version }} / Front: {{ $store.getters.appVersion }}</span>
        </div>
    </div>
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
    <!-- <div class="container-fluid container-p-x pb-3">
        <a href="#" class="footer-link pt-3">Link 1</a>
      <a href="#" class="footer-link pt-3 ml-4">Link 2</a>
    </div> -->
  </nav>
</div>
</template>

<script>
export default {
    name: 'app-layout-footer',
    data () {
        return {
            api_version: localStorage.getItem('version')
        }
    },
    methods: {
        getLayoutFooterBg () {
            return `bg-${this.layoutFooterBg}`
        }
    }
}
</script>

import moment from 'moment'

export default {
    // Format number to ARS currency
    currency: (value) => {
    // if (!value) return ''
        var formatter = new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2
        })
        return formatter.format(value)
    },

    age: (value) => {
        return moment().diff(moment(value), 'years')
    },
    shortDate: (value) => {
        return moment(value).format('DD/MM/YYYY ')
    },

    shortDateTime: (value) => {
        return moment(value).format('DD/MM/YYYY HH:mm')
    },
    shortHour: (value) => {
        return moment(value, 'hh:mm:ss').format('HH:mm')
    },

    friendlyDate: (value) => {
        return moment(value).format('dddd D [de] MMMM')
    },

    friendlyDateTime: (value) => {
        return moment(value).format('dddd D [de] MMMM [-] kk[:]mm [hs]')
    },

    fromNow: (value) => {
        return moment(value).fromNow()
    },

    shortTime: (value) => {
        return moment(value).format('HH:mm ')
    },

    // Format number to non decimal percentage
    percentage: (value, decimals) => {
        if (!value) value = 0
        if (!decimals) decimals = 0

        return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals) + '%'
    }
}

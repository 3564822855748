<template>
    <div>
        <!-- <b-navbar toggleable="lg" variant="navbar-theme " class="layout-navbar align-items-lg-center container-p-x"> -->
        <b-navbar toggleable="lg" variant="navbar-theme " class="layout-navbar align-items-lg-center navbar navbar-expand-lg navbar-light bg-light fixed-top ">
                <div class="container">
                    <!-- Brand -->
                <b-navbar-brand to="/">
                    <!-- <img :src="image_path + '/storage/assets/images/office/' +$store.state.office.logo" alt="" style="max-height: 30px"> -->
                    <strong>{{$store.state.office.name}}</strong></b-navbar-brand>
                <!-- <b-navbar-brand to="/"><strong>{{$store.state.office.name}}</strong></b-navbar-brand> -->

                <!-- Sidenav toggle -->
                <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
                    <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
                        <i class="ion ion-md-menu text-large align-middle" />
                    </a>
                </b-navbar-nav>

                <!-- Navbar toggle -->
                <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

                <b-collapse is-nav id="app-layout-navbar" center>
                    <b-navbar-nav class="ml-auto" center>
                        <b-nav-item href="/" :active="isMenuActive('/home')">Inicio</b-nav-item>
                        <b-nav-item href="/appointments" :active="isMenuActive('/appointments')">Turnos</b-nav-item>
                        <b-nav-item href="/studies" :active="isMenuActive('/studies')">Estudios</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>

                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>
                <b-navbar-nav class="">
                    <div v-if="!$auth.check()">
                        <router-link :to="{name: 'auth-login'}">login</router-link>
                    </div>
                    <div v-else>
                    <!-- Divider -->

                    <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
                        <template slot="button-content">
                            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                                <img :src="`${publicUrl}img/avatars/1.png`" alt class="d-block ui-w-30 rounded-circle">
                                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $auth.user().first_name }}</span>
                            </span>
                        </template>

                        <b-dd-item href="/me/profile"><i class="ion ion-ios-person text-lightest"></i> &nbsp; Mi perfil</b-dd-item>
                        <b-dd-item href="/me/change-password"><i class="ion ion-md-key text-lightest"></i> &nbsp; Cambiar contraseña</b-dd-item>
                        <!-- <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>
                        <b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item> -->
                        <b-dd-divider />
                        <router-link tag="b-dd-item" :to="{name:'logout'}"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Cerrar Sesión</router-link>
                    </b-nav-item-dropdown>
                    </div>
                </b-navbar-nav>
                </div>

            </b-navbar>

    </div>
</template>

<script>
export default {
    name: 'app-layout-navbar',

    props: {
        sidenavToggle: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        logout () {
            this.$store.dispatch('auth/logout')
        },

        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        },

        getLayoutNavbarBg () {
            return this.layoutNavbarBg
        },
        isMenuActive (url) {
            return this.$route.path.indexOf(url) === 0
        }
    }
}
</script>

import Vue from 'vue'
import axios from 'axios'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

Vue.use(auth, {
    plugins: {
        http: axios, // Axios
        router: Vue.router
    },
    drivers: {
        auth: {
            request: function (req, token) {
                this.drivers.http.setHeaders.call(this, req, {
                    Authorization: 'Bearer ' + token
                })

                if (req.url === this.options.refreshData.url) {
                    this.drivers.http.setHeaders.call(this, req, {
                        Refresh: this.token('refresh_token'),
                        Expiration: this.token('expires_at')
                    })
                }
            },
            response: function (res) {
                if (res.data.refresh_token && res.data.expires_at) {
                    this.token('refresh_token', res.data.refresh_token)
                    this.token('expires_at', res.data.expires_at)
                }

                // If version provided then update localStorage
                if (res.headers.version) {
                    localStorage.setItem('version', res.headers.version)
                }

                if (res.data.access_token) {
                    return res.data.access_token
                }
            }
        },
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter
    },
    options: {
        rolesVar: 'roles',
        tokenStore: ['localStorage', 'cookie'],
        // authRedirect: { name: 'auth-login' },
        forbiddenRedirect: { name: 'auth-login' },
        // notFoundRedirect: { name: '/' }, // Works when auth:false in route
        tokenDefaultName: 'token',
        logoutData: {
            url: '/logout',
            method: 'POST',
            redirect: '/',
            makeRequest: false
        },
        fetchData: {
            url: 'user',
            method: 'GET',
            enabled: true
        },
        refreshData: {
            url: '/refresh',
            method: 'POST',
            enabled: true,
            interval: 15
        },
        parseUserData: function (data) {
            return data
        }
    }
})

import Vue from 'vue'

// Axios
// import axios from 'axios'

const axios = require('axios').default
// import VueAxios from 'vue-axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/api'
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' }
// Vue.use(axios)
// Vue.use(VueAxios, axios);
Vue.axios = axios
Vue.prototype.$axios = axios
export default {
    root: process.env.VUE_APP_API_URL
}

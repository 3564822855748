// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'
import BootstrapVue from 'bootstrap-vue'

import http from './http'
import store from './store'
import config from './config'

import globals from './globals'
import Popper from 'popper.js'

// Helpers
import _ from 'lodash'

// Filters
import numericFilters from './filters/numerics'

import LaddaBtn from '@/vendor/libs/ladda/Ladda'

/* ***************
 * VEE-VALIDATE
 * ***************/
import { ValidationProvider, extend, ValidationObserver, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

import Multiselect from 'vue-multiselect'
import moment from 'moment'
// moment.tz.setDefault('America/Argentina')
moment.locale('es')
Vue.prototype.$moment = moment

localize('es', es)

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})
Vue.component('LaddaBtn', LaddaBtn)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Multiselect', Multiselect)

// Filters
Object.keys(numericFilters).forEach(key => Vue.filter(key, numericFilters[key]))
Vue.prototype._ = _
Vue.prototype.image_path = process.env.VUE_APP_IMAGE_URL
Vue.prototype.api_url = process.env.VUE_APP_IMAGE_URL
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue()

Vue.use(BootstrapVue)

// Global RTL flag
Vue.mixin({
    data: globals
})

// Set Front Version
store.commit('setPackageVersion', require('../package.json').version)

new Vue({
    http: http,
    store: store,
    router: router,
    config: config,
    render: h => h(App)
}).$mount('#app')

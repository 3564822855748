<template>
    <notifications group="notifications" position="top right" classes="vue-notification-template vue-notification "/>
</template>

<script>

import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {
    name: 'notifications-component',

    mounted () {
        const $this = this

        // Axios interceptors for showing messages
        this.$axios.interceptors.response.use(function (response) {
            // $this.$eventHub.$emit('notifySuccess', response)
            return response
        }, function (error) {
            if (!error.request.responseURL.includes('login')) $this.$eventHub.$emit('notifyError', error)
            return Promise.reject(error)
        })

        this.$eventHub.$on('notifySuccess', this.notifySuccess)
        this.$eventHub.$on('notifyWarning', this.notifyWarning)
        this.$eventHub.$on('notifySecondary', this.notifySecondary)
        this.$eventHub.$on('notifyError', this.notifyError)
        this.$eventHub.$on('notifyCustomError', this.notifyCustomError)
    },

    methods: {

        notifySuccess (data) {
            this.$notify({
                group: 'notifications',
                title: 'Correcto',
                type: 'success',
                text: data
            })
        },

        notifyWarning (data) {
            this.$notify({
                group: 'notifications',
                title: 'Atención',
                duration: 3000,
                type: 'warning',
                text: data
            })
        },

        notifySecondary (data) {
            this.$notify({
                group: 'notifications',
                title: 'Información',
                type: 'secondary',
                text: data
            })
        },

        notifyCustomError (data) {
            this.$notify({
                group: 'notifications',
                title: 'Acción requerida',
                type: 'danger',
                duration: 3000,
                text: data
            })
        },

        notifyError (error) {
            this.message = '[' + error.response.status + '] ' + error.response.data.message

            if (error.response.status === 422) {
                this.message = ''
                for (const [obj] of Object.values(error.response.data.errors)) {
                    this.message += `${obj}<br>`
                }
            }

            this.$notify({
                group: 'notifications',
                title: 'Error',
                type: 'bg-danger text-white',
                duration: 3000,
                text: this.message
            })
        }
    }

}
</script>

import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        auth
    },
    state: {
        packageVersion: '',
        office: {}
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion
        }
    },
    mutations: {
        setPackageVersion (state, version) {
            state.packageVersion = version
        },
        setOffice: function (state, office) {
            state.office = office
        }
    },
    strict: debug
})

<template>
  <div id="app">
    <notifications-component/>
    <router-view/>
  </div>
</template>

<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/_custom/theme-shadow-portal.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>

import Notifications from '@/components/partials/Notifications'

export default {
    name: 'app',
    components: {
        'notifications-component': Notifications
    },
    // metaInfo: {
    //     title: 'App',
    //     titleTemplate: '%s'
    // },
    created: function () {
        const self = this
        this.$axios.get('/portal/office/1')
            .then(function (res) {
                self.$store.commit('setOffice', res.data)
                document.title = self.$store.state.office.name
            })
            .catch(function () {
                // this.beepsLoading = false;
            })
    },
    updated () {
    // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1)
    }
}
</script>

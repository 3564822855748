import Vue from 'vue'

export default {
    namespaced: true,

    state () {
        return {

        }
    },

    actions: {
        resetPassword (ctx, data) {
            return new Promise((resolve, reject) => {
                Vue.axios
                    .post('/portal/password/reset/' + data.token, data)
                    .then(response => {
                        resolve(response)
                    }, reject)
                    .finally(response => {
                    })
            })
        },
        requestResetPassword (ctx, data) {
            return new Promise((resolve, reject) => {
                Vue.axios
                    .post('/portal/password/reset', { document_number: data.email })
                    .then(response => {
                        resolve(response)
                    }, reject)
                    .finally(response => {
                    })
            })
        },
        fetch (data) {
            return Vue.auth.fetch(data)
        },

        refresh (data) {
            return Vue.auth.refresh(data)
        },

        login (ctx, data) {
            data = data || {}

            return new Promise((resolve, reject) => {
                Vue.auth.login({
                    url: 'portal/login',
                    data: data.body,
                    remember: data.remember,
                    staySignedIn: data.staySignedIn
                })
                    .then((res) => {
                        if (data.remember) {
                            Vue.auth.remember(JSON.stringify({
                                name: ctx.getters.user.first_name
                            }))
                        }
                        const redirect = Vue.auth.redirect()

                        Vue.router.push(redirect ? redirect.from : '/')

                        resolve(res)
                    }, reject)
            })
        },

        register (ctx, data) {
            data = data || {}

            return new Promise((resolve, reject) => {
                Vue.auth.register({
                    url: 'portal/register',
                    data: data.body,
                    autoLogin: false
                })
                    .then((res) => {
                        if (data.autoLogin) {
                            ctx.dispatch('login', data).then(resolve, reject)
                        }
                    }, reject)
            })
        },

        impersonate (ctx, data) {
            // var props = this.getters['properties/data']

            Vue.auth.impersonate({
                url: 'auth/' + data.user.id + '/impersonate',
                redirect: 'user-account'
            })
        },

        unimpersonate (ctx) {
            Vue.auth.unimpersonate({
                redirect: 'admin-users'
            })
        },

        logout (ctx) {
            return Vue.auth.logout()
        }
    },

    getters: {
        user () {
            return Vue.auth.user()
        },

        impersonating () {
            return Vue.auth.impersonating()
        }
    }
}

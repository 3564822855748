import Vue from 'vue'
import VueRouter from 'vue-router'

import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'

import globals from '@/globals'

// Layouts
import Layout1 from '@/layout/Layout1'

Vue.use(VueRouter)
Vue.use(Meta)

Vue.router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'auth-login',
            component: () => import('@/components/auth/Login'),
            meta: {
                auth: false
            }
        },
        {
            name: 'logout',
            path: '/logout',
            component: () => import('@/components/auth/LogoutComponent'),
            meta: {
                auth: true
            }
        },
        {
            path: '/register',
            name: 'auth-register',
            component: () => import('@/components/auth/Register'),
            meta: {
                auth: false
            }
        }, {
            path: '/reset-password',
            name: 'auth-reset-password-request',
            component: () => import('@/components/auth/ResetPassword'),
            meta: {
                auth: false
            }
        }, {
            path: '/reset-password/:token/:document_number',
            name: 'reset-password-form',
            component: () => import('@/components/auth/ResetPasswordForm'),
            meta: {
                auth: false
            }
        },
        {
            path: '/',
            component: Layout1,
            meta: {
                auth: true
            },
            children: [
                {
                    name: 'calendars',
                    path: '/calendars',
                    component: () => import('@/components/CalendarsPage'),
                    meta: {
                        auth: undefined
                    }
                },
                {
                    path: '/',
                    redirect: '/dashboard'
                },
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    component: () => import('@/components/Dashboard')
                },
                {
                    name: 'studies',
                    path: 'studies',
                    component: () => import('@/components/Studies/IndexPage')
                },
                {
                    name: 'study',
                    path: 'study/:id',
                    props: true,
                    component: () => import('@/components/Studies/ShowPage')
                },
                {
                    name: 'appointments',
                    path: 'appointments',
                    component: () => import('@/components/AppointmentsPage')
                },
                {
                    path: 'me/profile',
                    component: () => import('@/components/user/ProfilePage')
                },
                {
                    path: 'me/change-password',
                    component: () => import('@/components/user/ChangePasswordPage')
                },
                {
                    name: 'booking',
                    path: 'booking/:id',
                    props: true,
                    component: () => import('@/components/BookingPage')
                }]
        }, {
            // 404 Not Found page
            path: '*',
            component: NotFound
        }]
})

Vue.router.afterEach(() => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0)
})

Vue.router.beforeEach((to, from, next) => {
    // Set loading state
    document.body.classList.add('app-loading')

    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10)
})

export default Vue.router
